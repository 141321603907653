import React, { useEffect } from "react";

const Chatbot = () => {
  useEffect(() => {
    // Configuration du chatbot
    window.VG_CONFIG = {
      ID: "h225cfq1akx5wyn0", // Remplacez par votre agent ID
      region: "eu", // Région de votre compte
      render: "bottom-right", // Position ou style ('full-width', 'bottom-left', etc.)
      stylesheets: [
        "https://vg-bunny-cdn.b-cdn.net/vg_live_build/styles.css",
      ],
      // Décommenter si vous souhaitez ajouter les données de l'utilisateur
      // user: {
      //   name: "John Doe",
      //   email: "johndoe@gmail.com",
      //   phone: "+1234567890",
      // },
    };

    // Ajouter dynamiquement le script du chatbot
    const script = document.createElement("script");
    script.src = "https://vg-bunny-cdn.b-cdn.net/vg_live_build/vg_bundle.js";
    script.defer = true; // Facultatif : Charger après la page
    document.body.appendChild(script);

    // Nettoyage pour éviter de recharger plusieurs fois le script
    return () => {
      const existingScript = document.querySelector(
        'script[src="https://vg-bunny-cdn.b-cdn.net/vg_live_build/vg_bundle.js"]'
      );
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, []); // Exécuté une seule fois lors du montage du composant


  return (
    <div
      style={{ width: 0, height: 0 }}
      id="VG_OVERLAY_CONTAINER"
    >
      HAMDI ------------------------------------------------
    </div>
  );
};

export default Chatbot;

